<template>
  <div class="sign-in-page sign-page">
    <div class="wrapper">
      <div class="left">
        <div class="text">
          <div class="desc">
            <b>Immerse yourself in the incredible world of prompts</b>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-wrapper">
          <div class="top">
            <router-link to="/" class="logo">
              <img src="./../images/logo.svg" class="img"/>
            </router-link>
            <router-link to="/" class="link">
              <img src="./../images/back.svg" class="img"/>
              <div class="desc">Back to main</div>
            </router-link>
          </div>
          <div class="content">
            <div class="title">Create account</div>
            <label class="short">
              <div class="desc">Name:</div>
              <input type="text" placeholder="Enter your name" v-model="name"/>
            </label>
            <label class="short">
              <div class="desc">Surname:</div>
              <input type="text" placeholder="Enter your surname" v-model="surname"/>
            </label>
            <label>
              <div class="desc">E-mail:</div>
              <input type="email" placeholder="Enter your e-mail" v-model="email"/>
            </label>
            <label>
              <div class="desc">Phone:</div>
              <input type="number" placeholder="Enter your phone" v-model="phone"/>
            </label>
            <label>
              <div class="desc">Password:</div>
              <input type="password" placeholder="Enter your password" v-model="pass"/>
            </label>
            <label>
              <div class="desc">Confirm password:</div>
              <input type="password" placeholder="Confirm your password" v-model="passConfirm"/>
            </label>
            <div class="chekbox-container">
              <label class="chekbox-label">
                <div class="chekbox-label-wrapper">
                  <input type="checkbox" name="terms" v-model="terms"/>
                  <div class="checkbox"></div>
                  <span class="desc">I agree with</span>
                  <a @click="$parent.goToPrivacy" class="desc link"> {{ $parent.textPageList[1].title }}</a>
                  <span class="desc"> and</span>
                  <a @click="$parent.goToTerms" class="desc link"> {{ $parent.textPageList[0].title }}</a>
                </div>
              </label>
            </div>
            <button :class="['button fill', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="submitRegister">
              <span>Create</span>
            </button>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
            <div class="bottom">
              <div class="desc">Already have account?</div>
              <router-link to="/sign-in" class="desc link">Log in</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignUp',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      pass: '',
      name: '',
      surname: '',
      phone: '',
      passConfirm: '',
      terms: false,
    }
  },
  mounted() {
    
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.email && this.pass && this.passConfirm
        && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    submitRegister() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phone,
        "password": this.pass,
        "passConfirm": this.passConfirm
      }
        this.$emit('registration', regData)
    }
  }
}
</script>