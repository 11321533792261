<template>
  <main class="main product-list-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="title big">Marketplace</div>
        </div>
      </section>
      <section class="section product-list-section2">
        <div class="wrapper">
          <div class="left">
            <div class="select-container" v-if="$parent.categoryOptions.length">
              <div class="top">
                <div class="top-left">
                  <div class="title">Categories</div>
                  <img src="./../images/down.svg" :class="['img', {'active': categoryListIsVisible}]" @click="categoryListIsVisible = !categoryListIsVisible"/>
                </div>
                <div class="top-right">
                  <div class="tag-list" v-if="category && category.length">
                    <template v-for="item in category">
                      <label class="product-list-checkbox" :key="item.id" v-if="item.name">
                        <input type="checkbox" v-model="category" :id="item.name" :value="item" @change="onSelectCategory()" />
                        <div class="desc">{{item.name}}</div>
                      </label> 
                    </template>
                    
                  </div>
                  <div class="desc clear" @click="clearFilters">
                    Clear all
                  </div>
                  <!-- <div class="desc reset" @click="resetFilters">
                    Reset all 
                  </div> -->
                </div>
              </div>
              <div class="product-list-checkbox-list-container" v-if="categoryListIsVisible">
                <template  v-for="(modelItem, i) in $parent.modelOptions">
                  <div class="product-list-checkbox-list" v-if="modelItem.id == model.id" :key="i">
                      <label class="product-list-checkbox" v-for="item in $parent.categoryCreateOptions.filter(item => item.parent_id === modelItem.id)" :key="item.id">
                        <input type="checkbox" v-model="category" :id="item.name" :value="item" @change="onSelectCategory()" />
                        <div class="desc">{{item.name}}</div>
                      </label>
                  </div>
                </template>
              </div>
              
              <div class="model-section" v-if="categoryListIsVisible">
                <div class="tab-list">
                  <template v-for="item in modelOptions">
                    <label class='tab-container' :key="item.id">
                      <input type="radio" v-model="model" :value="item" @change="onSelectModel()" />
                      <div class="tab">
                        <img v-if="item.image_url" :src="$parent.imgDomain + item.image_url" class="img"/>
                        <img v-else :class="['img', {'empty': !item.image_url}]"/>
                        <span>{{item.title}}</span>
                      </div>
                    </label>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <transition name="fade">
              <div class="list products" v-if="$parent.productList">
                <div class="item" v-for="(item, i) in $parent.productList" :key="i">
                  <ProductCard :currencyValue="currencyValue" @openBuyModal="openBuyModal" :models="$parent.modelOptions" :tag="item.parent_cat_id" :item="item" :sliderIsClickble="true" @goToProduct="goToProduct" :currency="currency"/>
                </div>
                <div class="title no-products" v-if="!$parent.productList.length">
                  
                </div>
                <div class="title no-products" v-if="!$parent.productList.length">
                  No products!
                </div>
                <div class="title no-products" v-if="!$parent.productList.length">
                  
                </div>
              </div>
            </transition>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';

export default {
  name: 'ProductList',
  props: ['currency', 'activeModels', 'activeCategories', 'models', 'modelOptions', 'currencyValue', 'categoryOptions'],
  components: {
    ProductCard
  },
  data: function() {
    return {
      imgDomain: '',
      category: [],
      model: [],
      productList: {},
      categoryListIsVisible: false
    }
  },
  watch: {
    activeModels: function(newVal) {
      this.model = newVal;
      if(this.categoryOptions) {
        this.category = this.categoryOptions.filter(item => item.parent_id === this.model.id);

      }
    },
    categoryOptions: function(newVal) {
      if(this.model) {
        this.category = newVal.filter(item => item.parent_id === this.model.id);
      }
    },
    activeCategories: function(newVal) {
       if (Array.isArray(newVal)) {
        this.category = newVal;
      } else {
        let newArray = [];
        newArray.push(newVal);
        let mapped = newArray.map(({
          id,
          title: name,
          parent_id
        }) => ({
          id,
          name,
          parent_id
        }));
        
        this.category = mapped;
      }
    },
    models: function(newVal) {
      this.$emit('setActiveModel', newVal);
    },
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    
    if (this.activeCategories) {
      if (Array.isArray(this.activeCategories)) {
        this.category = this.activeCategories;
      } else {
        let newArray = [];
        newArray.push(this.$parent.activeCategories);
        let mapped = newArray.map(({
          id,
          title: name,
          parent_id
        }) => ({
          id,
          name,
          parent_id
        }));
        this.category = mapped;
      }
    } 
    
    if (this.$parent.activeModels && this.$parent.activeModels.length) {
      if (Array.isArray(this.$parent.activeModels)) {
        this.model = this.$parent.activeModels;
      } else {
        let newArray = [];
        newArray.push(this.$parent.activeModels);
        this.model = newArray;
      }
    } else {
      this.$emit('setActiveModel', this.models);
    }
    
    if (this.$parent.activeCategory) {
      this.category = [];
      this.$emit('setActiveCategory', this.$parent.activeCategory);
      this.$emit('chooseCategory', '');

    }
  },
  methods: {
    onSelectModel() {
      this.$emit('setActiveModel', this.model);
    },
    onSelectCategory() {
      this.$emit('setActiveCategory', this.category);
    },
    resetFilters() {
      this.$parent.setAllModels();
      this.$parent.openProducts();
    },
    clearFilters() {
      this.$emit('chooseCategory', '');
      this.$emit('setActiveCategory', '');
      this.category = '';
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openBuyModal(item, type) {
      this.$emit('openBuyModal', item, type)
    }
  }
}
</script>