<template>
  <div class="sign-in-page sign-page">
    <div class="wrapper">
      <div class="left">
        <div class="text">
          <div class="desc">
            <b>Immerse yourself in the incredible world of prompts</b>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-wrapper">
          <div class="top">
            <router-link to="/" class="logo">
              <img src="./../images/logo.svg" class="img"/>
            </router-link>
            <router-link to="/" class="link">
              <img src="./../images/back.svg" class="img"/>
              <div class="desc">Back to main</div>
            </router-link>
          </div>
          <div class="content" v-if="loginContentVisible">
            <div class="title">Sign in</div>
            <label>
              <div class="desc">E-mail:</div>
              <input type="email" placeholder="Enter your e-mail" v-model="email"/>
            </label>
            <label>
              <div class="desc">Password:</div>
              <input type="password" placeholder="Enter your password" v-model="pass"/>
            </label>
            <div class="desc link" @click="switchToforgotPass">Forgot your password?</div>
            <button class="button fill" @click="submitLogin">
              <span>Log In</span>
            </button>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
            <div class="bottom">
              <div class="desc">Don't have an account?</div>
              <router-link to="/sign-up" class="desc link switch-register">Create your account now!</router-link>
            </div>
          </div>
          <div class="content" v-if="forgotPassContentVisible">
            <div class="title">Forgot password?</div>
            <label>
              <div class="desc">E-mail:</div>
              <input type="email" placeholder="Enter your e-mail" v-model="email"/>
            </label>
            <button class="button fill" @click="submitForgotPass">
              <span>Recover</span>
            </button>
            <transition name="fade">
              <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
            </transition>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
            <div class="bottom">
              <div class="desc">Already have an account?</div>
              <div class="desc link switch-login" @click="switchToLogin()">Sign in</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignIn',
  props: [],
  components: {
  },
  data: function() {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: ''
    }
  },
  mounted() {
    
  },
  methods: {
    switchToforgotPass() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100)
    },
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100)
    },
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }
      this.$emit('login', loginData)
    },
    submitForgotPass() {
      let data = {
        "email": this.email
      }
      this.$emit('forgotPass', data)
    },
  }
}
</script>